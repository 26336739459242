import { getMyUid, isMyProfileSet, setMyProfileId } from '../my'
import config from '@/config'
import { callSequence, getState, setStoreWrapper } from '../StoreManager'
import { initGA } from '../GAEvent'
import { prepareAwardConfig } from '../awards/awardsConfig'
import { isPanelType } from '../../modules/app/actions'
import { clearAllPosters } from '../posters/clearAllPosters'
import { isAIDatingApp, isDatingApp } from '../appConfigUtil'
import { datingNeedProfile } from '../../modules/posts/signals/postLike'
import { closeAllPanels } from '../../components/Panels/createPanelUtil'
import { imInRoom } from '../appUtil'
import { goToRoom } from '../navigateUtil'
import { getCurrentRoomId } from '../navigation/navigationUtil'
import { getMyLSProp } from '../LocalStorageUtil'
import { getProfilePostId } from '../userUtil'
import { showSnackbarNew } from '../../modules/app/factories'
import { getLocaleValue } from '../localeUil'
import { checkFillProfileNotification } from '../../modules/auth/actions/notificationUtil'
import { profileFillSnackbar } from '../snackbarUtil'
import { GENDER_SELECT_PANEL } from '../../components/Panels/types'

const { userloaderUrl } = config

export async function loadMyData(uid, params) {
  try {
    if (uid) {
      const response = await fetch(userloaderUrl + '/api/v1/userloader', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ uids: [uid] })
      })

      if (response && response.ok) {
        const respObj = await response.json()
        const userData = { ...params, ...respObj[uid] }
        userData.name = getState('auth.name')
        // userData.multi_buffs = getState('auth.multi_buffs')
        userData.storage = getState('auth.storage')
        const allUsers = getState('app.users') || {}

        allUsers[uid] = userData
        setStoreWrapper('app.users', allUsers)
        if (userData.gender === 'female') {
          setStoreWrapper('auth.female', true)
        } else if (!userData.gender || userData.gender === 'male') {
          setStoreWrapper('auth.female', false)
        }
        if (userData && userData.profile_post && userData.profile_post.postId) {
          setMyProfileId(userData.profile_post.postId)
        } else if (userData && userData.storage && userData.storage.profilePost && userData.storage.profilePost.postId) {
          setMyProfileId(userData.storage.profilePost.postId)
        }
        if (imInRoom()) {
          setStoreWrapper('forceSendGoInRoom', true)
          goToRoom(getCurrentRoomId())
        }
        setStoreWrapper('allMyDataLoaded', true)
        setStoreWrapper('genderSelectScreen', false)

        if (isPanelType(GENDER_SELECT_PANEL) || isPanelType('login')) {
          closeAllPanels()
          // callSequence('app.destroyPanelImmediately')
        }
        clearAllPosters()
        if (isDatingApp() && !isMyProfileSet() && !isAIDatingApp()) {
          setStoreWrapper('auth.isAuthComplete', true)
          datingNeedProfile('need_set_profile')
        } else {
          setStoreWrapper('auth.isAuthComplete', true)
        }

        callSequence('posts.getMorePosts')
        callSequence('auth.loadSpam')
        prepareAwardConfig(true)

        initGA()
      }
    }
  } catch (e) {
    console.log('@@@ myDataLoadError', e)
  }
}
