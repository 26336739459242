import { state } from 'cerebral'
import { postComplaintCheck, showPostInBlog } from '../../../utils/postUtils'
import { getMyUser } from '../../../utils/userLoader'

export const savePostsData = async ({ store, get, props }) => {
  const posts = props.posts
  if (!posts) {
    return
  }
  const postsData = get(state`posts.postsData`)
  const userPosts = get(state`posts.userPosts`)
  const similarPosts = get(state`posts.similarPosts`)

  const authorUids = []
  posts.forEach(post => {
    if (post.postId && postsData[post.postId] && postsData[post.postId].error) {
      postsData[post.postId] = null
    }
    if (post.originalTitle && post.postId === get(state`auth.profileId`)) {
      store.set('auth.name', post.originalTitle)
      const myUser = getMyUser()
      if (myUser) {
        myUser.name = post.originalTitle
        store.set(`app.users.${get(state`auth.uid`)}`, myUser)
      }
    }
    authorUids.push(post.uid)
    postsData[post.postId] = { ...postsData[post.postId], ...post }
    if (props.saveToUser) {
      if (!userPosts[post.uid]) {
        userPosts[post.uid] = []
      }
      if (userPosts[post.uid].indexOf(post.postId) === -1 && showPostInBlog(post) && !postComplaintCheck(post.postId)) {
        userPosts[post.uid].push(post.postId)
      }
    }

    if (props.similarTo) {
      if (!similarPosts[props.similarTo]) {
        similarPosts[props.similarTo] = []
      }
      if (similarPosts[props.similarTo].indexOf(post.postId) === -1 && showPostInBlog(post) && !postComplaintCheck(post.postId)) {
        similarPosts[props.similarTo].push(post.postId)
      }
    }

    const dateFormat = new Date(post.creationDate)
    //console.log('@Date string', dateFormat.toLocaleDateString([], { day: 'numeric', month: 'numeric', year: 'numeric' }))
    post.creationDateFormat = dateFormat.toLocaleDateString([], { day: 'numeric', month: 'numeric', year: 'numeric' })
  })

  store.set('posts.postsData', postsData)
  if (props.saveToUser) {
    store.set('posts.userPosts', userPosts)
  }
  store.set('posts.similarPosts', similarPosts)

  const postsId = []
  posts.forEach(post => {
    if (!post.commentsCount) {
      postsId.push(String(post.postId))
    }
  })
}
