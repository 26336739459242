import React from 'react'
import controller from '@/controller'
import { settingsPanel } from '../components/Panels/types'
import { wait } from '@/utils'
import { postRoute } from './routerHelpers'

export async function openPost(postId) {
  const panelsOnPop = controller.getSequence('app.panelsOnPop')
  panelsOnPop({ hideAll: true })
  await wait(300)

  const routeTo = controller.getSequence('app.prepareAndRouteTo')
  routeTo({ url: postRoute, urlValue: postId })
}

export const getPostLink = postId => {
  return (<u onClick={() => openPost(postId)} key={postId}
             style={{
               cursor: 'pointer',
               color: '#0084FF'
             }}>
    {'#' + postId}
  </u>)
}

const getLink = (txt, idx) => {
  const part1 = txt.substring(0, txt.indexOf(']'))
  const part2 = txt.substring(txt.indexOf('(') + 1, txt.indexOf(')'))
  const openShop = controller.getSequence('app.openShop')
  const createPanel = controller.getSequence('app.createPanel')
  const handlers = {
    shop: () => openShop({ message: '', reason: 'spam-click' }),
    'open_settings': () => createPanel(settingsPanel),
  }
  if (handlers[part2]) {
    const handler = handlers[part2]
    return (<a style={{ color: 'rgb(85, 26, 139)', textDecoration: 'underline' }}
               onClick={handler} key={idx}>
      {part1}
    </a>)
  }
  if (part2.indexOf('https') == 0) {
    return (<a href={part2} target="_blank" key={idx} rel="noreferrer">
      {part1}
    </a>)
  }
  return (<a href={part2} key={idx}>
    {part1}
  </a>)
}

export const replacePostLinks = text => {
  const result = []

  const texts = (' ' + text).split('#')

  for (let i = 0; i < texts.length; i++) {
    const txtPart = texts[i]
    if (i == 0 && text.charAt(0) != '#') {
      result.push(txtPart)
      continue
    }
    const postId = parseInt(txtPart)
    if (isNaN(postId)) {
      result.push(txtPart)
    } else {
      result.push(getPostLink(postId))
      result.push(txtPart.replace(/\d+/, ''))
    }
  }

  return result
}

export const replaceLinks = text => {
  const result = []
  if (!text) {
    return result
  }

  const texts = text.split('[')

  for (let i = 0; i < texts.length; i++) {
    const txtPart = texts[i]
    if (txtPart.indexOf(']') == -1) {
      result.push(txtPart)
    } else {
      result.push(getLink(txtPart, i))
      result.push(txtPart.substring(txtPart.indexOf(')') + 1))
    }
  }

  return result
}
